import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { AnalyticsService } from './analytics.service';
import { IAlertMsg, NotificationsService } from './notifications.service';
import { TIngegrator } from '../interfaces/datastructures';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  constructor(private http:HttpClient,
              private ga: AnalyticsService,
              private alertServ: NotificationsService,
  ) { }
  public _checkIntegration = new Subject<boolean>();

  private apiIntegrationLink(integrator: TIngegrator): Observable<any> {
    return this.http.post(environment.firebase.functionsUrl+'/v2/integrateOrgApp', { integrator: integrator });
  }

  private apiCheckIfOrgIntegrated(integrator: TIngegrator): Observable<any> {
    return this.http.post(environment.firebase.functionsUrl+'/v2/checkIfOrgIntegrated', { integrator: integrator });
  }

  private apiDeleteOrgIntegration(integrator: TIngegrator): Observable<any> {
    return this.http.post(environment.firebase.functionsUrl+'/v2/deleteIntegration', { integrator: integrator });
  }

  public openIntegrationLink(integratorId: TIngegrator): void {
    this.apiIntegrationLink(integratorId)
    .subscribe(
      response => {
        const url = response.url;
        window.location.href = url;

        this.ga.LogClickEvent({
          _eventCategory: 'Feature Usage',
          _eventAction: 'Integrate',
          _eventLabelName: 'Integrate for Org',
          _eventDataId: integratorId,
          _eventLabelValue: 1
        });

      },
      error => {
        const msg: IAlertMsg = {
          messageTitle: `Error opening ${integratorId} integration link`,
          message: `Verify if you have approriate permissions to integrate.`,
          errorStatus: `${error.status} - ${error.statusText}`
        }
        console.error(`Error opening ${integratorId} integration link: `, error);
        return this.alertServ.showAlert(msg, 'danger');
      }
    );
  }

  public checkIfOrgIntegrated(integrator: TIngegrator): Promise<any> {
    return new Promise((resolve, reject) => {
    this.apiCheckIfOrgIntegrated(integrator)
      .subscribe(
        (data: boolean) => {
          //console.log('single owner top 3 data:',data);
          resolve(data);
          //this._checkIntegration.next(data);
        },
        error => {
          reject(error); // Reject if there is an error in fetching data
          //this._checkIntegration.next(false);
        }
      );
    });
  }

  public deleteIntegration(integrator: TIngegrator): Promise<void> {
    return new Promise((resolve, reject) => {
    this.apiDeleteOrgIntegration(integrator)
      .subscribe(
        () => {
          resolve();
          //this.checkIfOrgIntegrated(integrator);
          this.ga.LogClickEvent({
            _eventCategory: 'Feature Usage',
            _eventAction: 'Delete Integration',
            _eventLabelName: 'Delete Integration for Org',
            _eventDataId: integrator,
            _eventLabelValue: -1
          });
        },
        error => {
          reject(error); // Reject if there is an error in fetching data
        }
      );
    });
  }





}
