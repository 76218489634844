import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { map, Observable } from 'rxjs';
import { IOwnerLisItem } from '../interfaces/ownerdata';
import { User } from './user';
import { environment } from 'src/environments/environment.dev';
import { shortCategoryAssignment, Tm1on1Category, Tm1on1CategoryShort } from '../interfaces/m1on1data';
import { IOrgUserSettings } from '../interfaces/datastructures';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  constructor(private authServ: AuthService,
              private afs: AngularFirestore 
  ) {
    
    //this.getLoggedUserAuthData();
   }

  //public authSubscription!: Subscription;
  //public currentUser: User;
  public _orgUserSettings!: Observable<IOrgUserSettings>;

  public getLoggedUserAuthData(): User | any {
    //this.authSubscription = 
    this.authServ.afAuth.authState.subscribe((user: User | any) => {
      let currentUser:User | null;
      if (user) {
       currentUser = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified
          }
      } else currentUser = null;
      return currentUser;
    });
  }
  
  /*
  public getLoggedMember(): IOwnerLisItem | any {

    this.getUserAuthData().then(() => {
      return {
        teamLeaderEmail: "leader@demo",
        dealOwnerId: 1234,
        dealOwnerEmail: "magdalena.okrzeja@gmail.com",
        dealOwnerFullName: "Magdalena Okrzeja",
        uid: "DesoEiHE80cJtK1LvwTLS3ZabFF2"
      }
    })
    */
    checkDealStatus(creationDateMillis: number, lastActivityDateMillis: number): 'old&inactive' | 'new' | 'inactive' | null  {
      const MS_IN_A_DAY = 24 * 60 * 60 * 1000;
      const HOW_OLD = environment.dataConfig.oldDealsCreatedDaysAgo || 14;
      const HOW_INACTIVE = environment.dataConfig.inactiveDealsActiveDaysAgo || 14;
    
      const currentDate = new Date();
      const creationDate = new Date(creationDateMillis);
      const lastActivityDate = new Date(lastActivityDateMillis);
    
      // Ensure that we are working with numbers
      const currentDateTime = currentDate.getTime();
      const creationDateTime = creationDate.getTime();
      const lastActivityDateTime = lastActivityDate.getTime();
    
      // Calculate the difference in days between the current date and the creation date
      const daysSinceCreation = Math.floor((currentDateTime - creationDateTime) / MS_IN_A_DAY);
    
      // Calculate the difference in days between the current date and the last activity date
      const daysSinceLastActivity = Math.floor((currentDateTime - lastActivityDateTime) / MS_IN_A_DAY);
    
      // Check if the creation date is older than two weeks and last activity date is older than one week
      const isOld = daysSinceCreation > HOW_OLD;
      const isInactive = daysSinceLastActivity > HOW_INACTIVE;
    
      if (isOld && isInactive) {
        return 'old&inactive'
      };

      if (isInactive) {
        return 'inactive'
      };
    
      if (!isOld) {
        return 'new'
      } else return null;
    
    }


    public convertMillisToTimestamp(millis: number | null) {
      const date = (millis) ? new Date(millis).toUTCString() : null;
      return date;
    }

    public convertMillisToDate(millis: number | null) {
      if (!millis) {
        return null;
      }
    
      const date = new Date(millis);
      
      const dateOnly = date.getUTCFullYear() + '-' + 
                        String(date.getUTCMonth() + 1).padStart(2, '0') + '-' +
                        String(date.getUTCDate()).padStart(2, '0');
      return dateOnly;
    }

    public convertMillisToDate2(millis: number): string {
      const date = new Date(millis);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based
      const day = String(date.getUTCDate()).padStart(2, '0');
      
      return `${year}-${month}-${day}`;
  }

  public shorterCategory(category: Tm1on1Category): Tm1on1CategoryShort | any {
    const shortCategory = shortCategoryAssignment.find(arr => arr[0] == category);
    return shortCategory ? shortCategory[1] : null;
  }

  formatNumber(number: number): string {
    let value = number;
    let formattedNumber;
    if (value && !isNaN(value)) {
      formattedNumber = new Intl.NumberFormat('en-US').format(Number(value));
    }
    return ''+(formattedNumber || ' n/a');
  }

  public getOrgUserSettings(user: User, currOrgCode: string): Observable<IOrgUserSettings> {
    //const currOrgCode = JSON.parse(localStorage.getItem('orgCodeCurr')!);
    this._orgUserSettings = this.afs
    .collection('users')
    .doc(user.uid)
    .collection('user-orgs')
    .doc(currOrgCode)
      .valueChanges()
      .pipe(
        map((data: any) => {
          // Extract specific fields here
          return data;
        })
      )
  
    return this._orgUserSettings;
  }

  public saveOrgUserSettingsPipelines(user: User, currOrgCode: string, pipelines: string[]) {
    const userRef: AngularFirestoreDocument<any> = 
    this.afs.collection('users')
    .doc(user.uid)
    .collection('user-orgs')
    .doc(currOrgCode);

    const settings: IOrgUserSettings = {
      selectedPipelines: pipelines
    }

    return userRef.set(settings, {
      merge: true,
    });

  }
    
  }