import { IInsightState } from "./dealdata";

export interface ICardInsights1on1PatternItem {
    insightId:  number,
    aimars_LastGenerated:  number,
    lastSourceTextCreatedMillis:  number,
    engCreatedDay:  number,
    engCreatedMonth:  number,
    engCreatedYear:  number,
    insightText:  string,
    insightWhy:  string,
    patternTopic:  string,
    topic:  string,
    subject: string,
    subject_name: string,
    subject_object: string,
    category:  Tm1on1Category,// | string,
    insightIds:  string[],	
    dealIds:  string[],
    dealOwnerEmails: any[],
    teamLeaderEmail?: string,
    pipeline: string,
    numOfopenDealsAtGenTime: number, //by pipeline if we group by pipeline
    // only for update functions and storing (not coming from source)
    dealId?: string 
    lastStatusBy?: string,
    lastUpdated?: number,
    dealAmountSum: number,
    insightState: IInsightState,
    //icp elements to filter:
    industryList:  string[],
    countryRegionList:  string[],
    companySizeRangeList:  string[],
    companyNameList:  string[],
    amountRangeList:  string[]
}

export type Tm1on1Category = 
    'Missing feature'
    | 'Ask for discount'
    | 'Technical questions'
    | 'Certifications'
    | 'Asking for feature list / testimonials'
    | 'Finance / legal: contract related asks'
    | 'Competition';

export type Tm1on1CategoryShort = 
'Features'
| 'Discount'
| 'Technical'
| 'Certifications'
| 'Info Request'
| 'Finance / Legal'
| 'Competition';

export const shortCategoryAssignment:[Tm1on1Category, Tm1on1CategoryShort, string][] = [
['Missing feature', 'Features', 'Discovered patterns related to product features'],
['Ask for discount', 'Discount', 'Discovered patterns around asks for discounts'],
['Technical questions', 'Technical', 'Discovered patterns to technical issues, enquiries etc.'],
['Certifications', 'Certifications', 'Discovered patterns to customer interest on certifications'],
['Asking for feature list / testimonials', 'Info Request', 'Found pattern in customer requests'],
['Finance / legal: contract related asks', 'Finance / Legal', 'Found pattern in finacial or legal asks, requests etc.'],
['Competition', 'Competition', 'Discovered pattern in customers refering to competition offerings']
]

export interface ICardInsights1on1PatternStatsByCategory {
    aimars_LastGenerated: number,
    lastSourceTextCreatedMillis: number,
    category: Tm1on1Category,
    topics: string[],
    patternTopics: string,
    patternInsightIds: string,
    detailedInsighIds: string[],
    dealIds: string[],
    dealOwnerEmails: string[],
    numOfTopics: number,
    numOfpatternTopics: number,
    numOfpatternInsightIds: number,
    numOfdetailedInsighIds: number,
    numOfdealIds: number,
    numOfdealOwnerEmails: number,
    m1on1RankByCategory: number,
    dealAmountSum: number,
    teamLeaderEmail?:string //unused for now
}

export const PATTERN_CATEGORIES: Tm1on1Category[] = [
    'Missing feature',
    'Ask for discount',
    'Technical questions',
    'Certifications',
    'Asking for feature list / testimonials',
    'Finance / legal: contract related asks',
    'Competition',
  ];