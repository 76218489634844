<!--<button [cModalToggle]="staticBackdropModalConfirm.id" cButton>Launch static backdrop modal</button>-->
<c-modal [visible]="showModal" #verticallyCenteredModal #staticBackdropModalConfirm backdrop="static" id="staticBackdropModalConfirm">
  <c-modal-header>
    <h5 cModalTitle>{{confirmMessage?.messageTitle || 'This is message title'}}</h5>
    <button [cModalToggle]="staticBackdropModalConfirm.id" cButtonClose></button>
  </c-modal-header>
  <c-modal-body><b>{{confirmMessage?.message || 'This is the message you need to confirm or cancel. Click confirm or cancel.'}}</b>
  </c-modal-body>
  <ng-container  *ngIf="modalType !='info'">
    <c-modal-footer>
      <button [cModalToggle]="staticBackdropModalConfirm.id" 
          cButton 
          color="secondary"
          (click)="onCancel()">
          Cancel
      </button>
      <button cButton 
          [color]="modType" 
          (click)="onConfirm()">
          Confirm
      </button>
    </c-modal-footer>
  </ng-container>
  <ng-container  *ngIf="modalType =='info'">
    <c-modal-footer>
      <!--<button [cModalToggle]="staticBackdropModalConfirm.id" 
      cButton 
      color="secondary"
      (click)="onCancel()">
      Cancel
  </button>-->
  <button cButton 
      [color]="modType" 
      (click)="onConfirm()">
      Ok, I understand
  </button>
    </c-modal-footer>
  </ng-container>
  
  
</c-modal>
