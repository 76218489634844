import { Injectable, NgZone } from '@angular/core';
import { /*IOrgCode,*/ User } from '../services/user';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { DBsourceService } from './dbsource.service';
import { Subject } from 'rxjs/internal/Subject';
import { OrgsettingService } from './orgsetting.service';
import { OrgdataService } from './orgdata.service';
import { UserInvitationsService } from './user-invitations.service';
import { BehaviorSubject, Observable, filter, from, map, of, switchMap, take } from 'rxjs';
import { IOrgInvite, TOrgRole } from '../interfaces/invites';
import { IOwnerLisItem } from '../interfaces/ownerdata';
import { AnalyticsService } from './analytics.service';

export type IModalAfterLogin = 'demoorcontact';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // Save logged in user data
  userMetadata: any;

  public _getCurrUser = new BehaviorSubject<any>(null);
  private currUser!: User;
  public _modalAfterLogin = new Subject<IModalAfterLogin>();
  //public _allowUserAccess = new BehaviorSubject<boolean>(false);
  public _navPermit = new BehaviorSubject<null>(null);
  //public _passUserAndOrg = new BehaviorSubject<{user: User | null, currOrgCode: string}>({user: null, currOrgCode:''});
  public _passUserAndOrg = new Subject<{user: User | null, currOrgCode: string}>();
  public _checkInvites = new BehaviorSubject<null>(null);
  //public _getOrgCode = new Subject<IOrgCode>(); 
  private lastOrgId: string | null = null;

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public db: DBsourceService,
    private ga: AnalyticsService
  ) {
    //console.log('START AUTH SERV');
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.getUserDetails();
    /*
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        console.log('Subscribe to Auth, get user', user);
        this.userData = user;
        //this.getUserMetadata(user.email);
        localStorage.setItem('user', JSON.stringify(user));
        JSON.parse(localStorage.getItem('user')!);
        this._getCurrUser.next(user);

        localStorage.setItem('orgCodes', 'null');
        localStorage.setItem('orgCodeCurr', 'null');

      } else {
        localStorage.setItem('user', 'null');
        localStorage.setItem('userMetadata', 'null');
        localStorage.setItem('orgCodes', 'null');
        localStorage.setItem('orgCodeCurr', 'null');
        JSON.parse(localStorage.getItem('user')!);
        JSON.parse(localStorage.getItem('userMetadata')!);
        JSON.parse(localStorage.getItem('orgCodes')!);
        JSON.parse(localStorage.getItem('orgCodeCurr')!);
        
      }
    });*/


}

getLastUserOrg() {
  return this._passUserAndOrg.asObservable();
}

  // Sign in with email/password
  SignIn2(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        console.log('Signed in with login and password');
        this.afAuth.authState.subscribe((user) => {
          if (user) {
            this.SetUserData(result.user);
            //this.router.navigate(['do-dashboard']);  
            this.navigateToView();    
          }
        });
      })
      .then (()=>{
        setTimeout(()=>
        this.TriggerSomeActionsOnLogging()
        ,2000);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  // Sign up with email/password
  SignIn(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        let loggingEmail = result?.user?.email;
        console.log('Sign In result: ', result);
        this.SetUserData(result.user);
        this.isUserAllowedToLog(loggingEmail||'')
        .then((isAllowed)=> {
          console.log('Pass?', isAllowed, loggingEmail);
          if(isAllowed){
            console.log('Let this email in..');
            this.navigateToView();
            //this.router.navigate(['do-dashboard']); 
            this.forcedSignout = false;
          } else {
            this.forcedSignout = true;
            this.router.navigate(['login']);
            localStorage.setItem('user', 'null');
              /*this.SignOut().finally(()=> {
                this.forcedSignout = true;
                console.log('Force signout1', this.forcedSignout);
                //window.alert('You are not authorized to use this application. Contact AIMARS team to get access...');
              });*/
            //this.router.navigate(['dashboard']);
          }
      })
      })
      .then (()=>{
        setTimeout(()=>
        this.TriggerSomeActionsOnLogging()
        ,2000);
      })
      .then(()=> {
        this.ga.LogClickEvent({
          _eventCategory: 'App Access',
          _eventAction: 'App Logging',
          _eventLabelName: 'LogIn with User & Password',
        }, this.lastOrgId)
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }
  
// Sign up with email/password
  SignUp(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        let loggingEmail = result?.user?.email;
        console.log('Sign Up result: ', result);
        this.SetUserData(result?.user);
        this.isUserAllowedToLog(loggingEmail||'')
        .then((isAllowed)=> {
          console.log('Pass?', isAllowed, loggingEmail);
          if(isAllowed){
            console.log('Let this email in..');
            this.SendVerificationMail();
            //this._allowUserAccess.next(true);
            this.forcedSignout = false;
          } else {
            this.forcedSignout = true;
            this.router.navigate(['register']);
            localStorage.setItem('user', 'null');
              /*this.SignOut().finally(()=> {
                this.forcedSignout = true;
                console.log('Force signout1', this.forcedSignout);
                //window.alert('You are not authorized to use this application. Contact AIMARS team to get access...');
              });*/
            //this.router.navigate(['dashboard']);
          }
      })
      })
      .then(()=> {
        this.ga.LogClickEvent({
          _eventCategory: 'App Access',
          _eventAction: 'App SignUp',
          _eventLabelName: 'SignUp with User & Password'
        }, this.lastOrgId)
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }
  

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['verify-email']);
      });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }

 async isLoggedInSimple() {
  const user = JSON.parse(localStorage.getItem('user')!);
  console.log('User in local storage (inLoggedSimple): ', user);
  return user !== null && user.emailVerified !== false ? true : false;
}

isLoggedInOb(): Observable<boolean> {
  return new Observable<boolean>(observer => {
    try {
      const userJson = localStorage.getItem('user');
      if (!userJson) {
        observer.next(false);
      } else {
        const user = JSON.parse(userJson);
        console.log('User in local storage: ', user);
        observer.next(user !== null && user.emailVerified !== false);
      }
      observer.complete();
    } catch (error) {
      observer.error(error);
    }
  });
}

isLoggedIn(): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    try {
      const userJson = localStorage.getItem('user');
      if (!userJson) {
        resolve(false);
      } else {
        const user = JSON.parse(userJson);
        //console.log('User in local storage: ', user);
        resolve(user !== null && user.emailVerified !== false);
      }
    } catch (error) {
      reject(error);
    }
  });
}


  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider()).then((res: any) => {
      console.log('Navigate to dashbaord')
      //this.router.navigate(['do-dashboard']);
      this.navigateToView();
      return res;
    })
    .then(()=> {
      this.ga.LogClickEvent({
        _eventCategory: 'App Access',
        _eventAction: 'App Logging',
        _eventLabelName: 'LogIn with Google'
      },this.lastOrgId)
    })
    .then (()=>{
      setTimeout(()=>
      this.TriggerSomeActionsOnLogging()
      ,2000);
    });
  }
/*
  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        let loggingEmail = result.user?.email;
        console.log('Google result: ', loggingEmail);
        //this._getCurrUser.next(result.user);
        this.SetUserData(result.user);

        //this.db.getUserMetadata(result.user?.email);
      })
      .catch((error) => {
        window.alert(error);
      });
    }
*/


  // Auth logic to run auth providers
  public forcedSignout: boolean = false;

  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        let loggingEmail = result?.user?.email;
        console.log('Google result: ', loggingEmail);
        this.SetUserData(result.user);
        this.isUserAllowedToLog(loggingEmail||'')
        .then((isAllowed)=> {
          console.log('Pass?', isAllowed, loggingEmail);
          if(isAllowed){
            console.log('Let this email in..');
            
            //this.SetUserData(result.user);
            this.forcedSignout = false;

          } else {
              this.SignOut().finally(()=> {
                this.forcedSignout = true;
                console.log('Force signout1', this.forcedSignout);
                //window.alert('You are not authorized to use this application. Contact AIMARS team to get access...');
              });
            //this.router.navigate(['dashboard']);
          }
      })
      //.then(()=> )
      .catch((error ) => {
        this.SignOut().finally(()=> {
          this.forcedSignout = true;
          console.log('Force signout1', this.forcedSignout);
          //window.alert('You are not authorized to use this application. Contact AIMARS team to get access...');
        });
      })
      })
      //.then
      .catch((error) => {
        window.alert(error);
      });

  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  async SetUserData(user: any) {
    console.log('Set user data:', user);

    await this.getUserDetails();
  }

  // Sign out
  SignOut() {

    return this.afAuth.signOut()
    .then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('userMetadata');
      localStorage.setItem('userMetadata', 'null');
      localStorage.removeItem('orgCodes');
      localStorage.setItem('orgCodes', 'null');
      localStorage.removeItem('orgCodeCurr');
      localStorage.setItem('orgCodeCurr', 'null');
      this.router.navigate(['login']);
    })
    .then(()=> {
      this.ga.LogClickEvent({
        _eventCategory: 'App Access',
        _eventAction: 'App Logout',
        _eventLabelName: 'LogOut'
      }, this.lastOrgId)
    });
  }


  TriggerSomeActionsOnLogging(){
    
    const userJson = localStorage.getItem('user');
    const user = (userJson) ? JSON.parse(userJson) : null;
    console.log('Curr user after loggin triggers', user);


    const inviteAwaits = false;
    //const hasAnyOrg = this.hasAnyOrg(this.currUser).subscribe(org => {return org});
    //this.getOrgCodes();
    if (inviteAwaits){
        //1. Check if user has invite to some org (always on login)    
        //2. if yes, ask if wants to accept invite, then let in or delete invite (show as rejected)
        //1. Check if user has any invite to org, if yes then accept or reject and go to new org
    };
    
    this.hasAnyOrg(user).subscribe(hasOrg => {
      console.log('has orgs: ', user,hasOrg);
      if (!hasOrg){
        this._checkInvites.next(null);
        // Turn off as we have many demos now - to adjust
        //this._modalAfterLogin.next('demoorcontact');
      }
    });

    //this._getCurrUser.next(result.user);

  }

  hasAnyOrg(user: User): Observable<boolean> {
    return this.getCurrOrgs(user).pipe(
      map(orgCodesCurr => {
        const hasOrgs = orgCodesCurr.length > 0;
        console.log('Has any orgs: ', hasOrgs);
        return hasOrgs;
      })
    );
  }

  getCurrOrgs(user: User): Observable<string[]> {
    const userRef: AngularFirestoreDocument<User> = this.afs.collection('users')?.doc(user.uid);
    console.log("Get curr user orgs",user);

    
    return from(userRef.valueChanges()).pipe(
      filter(doc => !!doc && !!doc.orgCodes), // Wait for doc and orgCodes field
      take(1), // Ensure only one emission
      map(doc => {
        console.log('User exists, get org:', doc);
        const orgCodesCurr: string[]|any = doc?.orgCodes;
        console.log('Get org codes:', orgCodesCurr);
        return orgCodesCurr;
      })
    );
  }

  public redirectToAppIfLogged(){
    return this.afAuth.authState.subscribe((user) => {
      if (user) {
        console.log('Redirect to app');
        this._getCurrUser.next(user);
        this.router.navigate(['do-dashboard']);
     }
     else throw Error('Cant log, no user found');
  })
    
  };

  public redirectToLoginIfNotLogged(){
    return this.afAuth.authState.subscribe((user) => {
      if (!user) {
        console.log('Redirect to login');
        this.router.navigate(['login']);
     }
     else throw Error('Cant log, no user found');
  })
    
  };

  public getCurrentLoggedUser(){
    return this.afAuth.authState.subscribe((user) => {
      if (user) { this._getCurrUser.next(user)} 
      else throw Error('Cant log, no user found');
    });
  }
  async isUserAllowedToLog(loggingEmail: string): Promise<boolean> {
    try {
        const doc:any = await this.afs.collection('app-access')?.doc(loggingEmail).get().toPromise();
        if (doc?.exists) {
            return true; // User is allowed to log
        } else {
            // User is not allowed to log
            // Perform any necessary actions here, such as logging out and removing UID entry
            return false;
        }
    } catch (error) {
        // Handle error here
        console.error("Error occurred while checking user access:", error);
        throw error; // Rethrow the error to be caught by the caller
    }
  }

  private async isAdmin2(): Promise<boolean> {
    const currOrg = localStorage.getItem('orgCodeCurr');
    if (!currOrg) {
      return false;
    }
  
    const user = await this.afAuth.authState.pipe(take(1)).toPromise();
    if (!user) {
      return false;
    }
  
    const userRef: AngularFirestoreDocument<IOrgInvite> = this.afs
      .collection('users')
      .doc(user.uid)
      .collection('access-orgs')
      .doc(currOrg);
  
    const doc = await userRef.get().pipe(take(1)).toPromise();
    if (!doc?.exists) {
      return false;
    }
  
    const currRoles: TOrgRole[] = doc.data()?.orgRoles || [];
    return currRoles.includes('admin');
  }

public isAdmin(): Observable<boolean> {
  return this.afAuth.authState.pipe(
    take(1),
    switchMap(user => {
      if (!user) {
        return of(false); // User not logged in
      }
      const currOrg = JSON.parse(localStorage.getItem('orgCodeCurr')!);
      if (!currOrg) {
        return of(false); // No current org code
      }

      const userRef: AngularFirestoreDocument<IOrgInvite> = this.afs
        .collection('users')?.doc(user?.uid)
        .collection('access-orgs')?.doc(currOrg);

      return userRef.valueChanges().pipe(
        take(1),
        map(doc => {
          //console.log('Access for:', currOrg, doc)
          if (doc && doc?.orgRoles) {
            return doc?.orgRoles?.includes('admin');
          } else {
            return false;
          }
        })
      );
    })
  );
}
  public async waitAndGetCurrentOrg(timeout: number): Promise<string | null> {
    return new Promise<string | null>((resolve) => {
      setTimeout(() => {
        const currOrg = localStorage.getItem('orgCodeCurr');
        resolve(currOrg);
      }, timeout);
    });
  }


  public clearLocalStorage() {
    localStorage.setItem('user', 'null');
    localStorage.setItem('userMetadata', 'null');
    localStorage.setItem('orgCodes', 'null');
    localStorage.setItem('orgCodeCurr', 'null');
    JSON.parse(localStorage.getItem('user')!);
    JSON.parse(localStorage.getItem('userMetadata')!);
    JSON.parse(localStorage.getItem('orgCodes')!);
    JSON.parse(localStorage.getItem('orgCodeCurr')!);
  }

  /************ sign in link sending **************************/
  /*
  //  firebase introduced limit to 5 email links/day :-((((
  email = 'magda@aimars.io';
  emailSent: boolean = false; 
  errorMessage: string = 'Err msg link sent';
  

  actionCodeSettings = {
    // Your redirect URL
    url: 'http://localhost:4200/#/register', 
    handleCodeInApp: true,
  };


    //async 
    sendEmailLink() {
      console.log('Send email link to: ',  this.email );
      const actionCodeSettings = { ...this.actionCodeSettings };
      try {
        //await 
        this.afAuth.sendSignInLinkToEmail(
          this.email,
          actionCodeSettings
        );
        window.localStorage.setItem('emailForSignIn', this.email);
        this.emailSent = true;
      } catch (err) {
        this.errorMessage = 'err.message';
      }
    }
    
    url = this.router.url;
    async confirmSignIn() {
      console.log('Confirming signing in...');
      try {
        if ( await this.afAuth.isSignInWithEmailLink(this.url)) {
          let email = window.localStorage.getItem('emailForSignIn');
  
          // If missing email, prompt user for it
          if (!email) {
            email = window.prompt('Please provide your email for confirmation');
          }
  
          // Signin user and remove the email localStorage
          const result = await this.afAuth.signInWithEmailLink(this.email, this.url);
          window.localStorage.removeItem('emailForSignIn');
        }
      } catch (err) {
        this.errorMessage = 'err.message';
      }
    }
  /************ end of: sign in link sending **************************/


  private waitForAuthState(): Promise<User | null> {
    return new Promise<User | null>((resolve, reject) => {
      this.afAuth.authState.subscribe(async (user:any) => {
        if (user) {
          //console.log('Subscribe to Auth, get user', user);
          this.userData = user;
          //this.userData = await this.getUserDetailsFromDB(user) || user;
          localStorage.setItem('user', JSON.stringify(user));
          this._getCurrUser.next(user);

          //localStorage.setItem('orgCodes', 'null');
          //localStorage.setItem('orgCodeCurr', 'null');
          resolve(user); // Resolve with the user object
        } else {
          this.clearLocalStorage();
          resolve(null); // Resolve with null if no user is logged in
        }
      });
    });
  }

  async getUserDetails(): Promise<void> {
    //console.log('||||||||||||||||| Get user details like user and org')
    try {
      const user = await this.waitForAuthState();
      if (user) {
        //console.log('||||||||||||||||||| User is logged in:', user);
        const currOrgCode:any = await this.getUserDetailsAndOrgCode(user);
        //const updatedUser: User | null = await this.updateUserDetailsFromDB(user);
        //console.log('|||||||||||||||||||| User details and org code processed', user, currOrgCode);
        // Proceed with further logic here
        this._passUserAndOrg.next({
          user: user,
          currOrgCode: currOrgCode+''
        });
      } else {
        //console.log('|||||||||||||||||||| No user is logged in');
        // Handle the case when no user is logged in
      }
    } catch (error) {
      //console.error('||||||||||||||||||||| Error processing auth state or user details:', error);
    }
  }

  async replaceUserDetailsFromDB(user: User): Promise<User | null> {
    //console.log('LOGIN - update user data with db usser data', user);
    try {
      const userRef: AngularFirestoreDocument<User> = this.afs.collection('users').doc(user?.uid);
      const doc = await userRef.get().toPromise();
        if(doc?.exists) {
          const data:any = doc?.data();
          //console.log('LOGIN - update user data',user,' with db user data', data);
          return {
            uid: user.uid || data?.uid,
            email: user.email || data?.email,
            displayName: data?.displayName || user.displayName, //take first saved display name
            photoURL: user.photoURL || data?.photoURL,
            emailVerified: user.emailVerified || data?.emailVerified,
            orgCodes: data?.orgCodes,
            createdAt: user?.metadata?.createdAt,
            lastLoginAt: user?.metadata?.lastLoginAt
          }
        } else return {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified,
          orgCodes: [],
          createdAt: user?.metadata?.createdAt,
          lastLoginAt: user?.metadata?.lastLoginAt
        };
    } catch (error) {
      console.error('Error getting user document and updating with db data', error);
      throw error; // Re-throw the error to handle it outside
    }

  }

/*
  async getUserDetailsFromDB(user: User): Promise<User | null> {
    console.log('Get user data with db usser data', user);
    try {
      const userRef: AngularFirestoreDocument<User> = this.afs.collection('users').doc(user?.uid);
      const doc = await userRef.get().toPromise();
        if(doc?.exists) {
          const data = doc?.data();
          console.log('Get db user data', data);
          return data || null;
        } else return null;
    } catch (error) {
      console.error('Error getting user document and updating with db data', error);
      throw error; // Re-throw the error to handle it outside
    }

  }
*/

  async getUserDetailsAndOrgCode(user: User): Promise<string | []> {
    try {
      const userRef: AngularFirestoreDocument<User> = this.afs.collection('users').doc(user.uid);
      const doc = await userRef.get().toPromise();

      if (doc?.exists) {
        const orgCodes = doc.data()?.orgCodes ? doc.data()?.orgCodes : [];
        
        let orgCodeCurrent =  JSON.parse(localStorage.getItem('orgCodeCurr')!);
        
        if (!orgCodeCurrent) {
          
          orgCodeCurrent = orgCodes ? this.getOrgCodeNotDemo(orgCodes) : null;
          localStorage.setItem('orgCodeCurr', JSON.stringify(orgCodeCurrent));
          //JSON.parse(localStorage.getItem('orgCodeCurr')!);

          console.log('LOGIN - No current org, change to default from database:', orgCodeCurrent);
          //this._passUserAndOrg.next(orgCodeCurrent);
        }

        localStorage.setItem('orgCodes', JSON.stringify(orgCodes));
        //JSON.parse(localStorage.getItem('orgCodes')!);

        let userData:any = await this.replaceUserDetailsFromDB(user);

        userRef.set(userData, {
          merge: true,
        });
        this.lastOrgId = orgCodeCurrent;
       
        return orgCodeCurrent;
      } else {
          
          let userData:any = await this.replaceUserDetailsFromDB(user);

          localStorage.setItem('orgCodes', JSON.stringify([]));
          //JSON.parse(localStorage.getItem('orgCodes')!);
          localStorage.setItem('orgCodeCurr', JSON.stringify(null));
          //JSON.parse(localStorage.getItem('orgCodeCurr')!);

          console.log('User does not exist.', userData);
        
        userRef.set(userData, {
          merge: true,
        });

        return [];
      }
    } catch (error) {
      console.error('Error getting user document:', error);
      throw error; // Re-throw the error to handle it outside
    }
  }

  public getOrgCodeNotDemo(orgCodes: string[]): string | null {
    const nonDemoCodes = orgCodes.filter(code => !code.includes('demo'));
    
    if (nonDemoCodes.length > 0) {
      console.log(')))))))) cur org:',nonDemoCodes[0])
      return nonDemoCodes[0];
    } else if (orgCodes.includes('demo')) {
      console.log(')))))))) cur org:','demo - selected')
      return orgCodes[0];
    } else {
      console.log(')))))))) cur org:','null - selected')
      return orgCodes[0];
    }
  }


  private navigateToView(){
    const redirectUrl = localStorage.getItem('redirectUrl') || '/do-dashboard';
    console.log('Navigate to: ', redirectUrl);
    localStorage.removeItem('redirectUrl');
    this.router.navigateByUrl(redirectUrl);
  }


}
