import { Injectable } from '@angular/core';
import { TIngegrator, TInsightCategory } from '../../interfaces/datastructures';
import { map, Observable, of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { HttpClient } from '@angular/common/http';
import { DBsourceService } from '../dbsource.service';
import { IRespSlackConversationList, IRespSlackMembersList } from '../../interfaces/dataresp';
import { ICardInsightsItem } from '../../interfaces/dealdata';
import { ICardInsights1on1Item } from '../../interfaces/ownerdata';
import { NotificationsService } from '../notifications.service';
import { stringify } from 'querystring';
import { ShareContentService } from '../share-content.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getAuth, User, UserInfo } from 'firebase/auth';
import { ICardInsights1on1PatternItem } from '../../interfaces/m1on1data';
import { UserSettingsService } from '../user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class ApiSlackService {

  public _dataSlackConversationList = new Subject<IRespSlackConversationList>();
  public _dataSlackMembersList = new Subject<IRespSlackMembersList>();

  constructor(private http: HttpClient,
              //private ga: AnalyticsService,
              private alertServ: NotificationsService,
              private storageSrc: DBsourceService,
              private share: ShareContentService,
              private afAuth: AngularFireAuth,
              private uSet: UserSettingsService
  ) { 

  }

    //Slack integrations:
  
    private apiCheckSlack(integrator: TIngegrator): Observable<any> {
      return this.http.post(environment.firebase.functionsUrl+'/checkSlack', { integrator: integrator });
    }

    private apiSendMessageToSlack(integrator: TIngegrator, slackMsg: any/*, channelId:string, msgText:string, url:string*/): Observable<any> {
      return this.http.post(environment.firebase.functionsUrl+'/sendMessageToSlack', { 
        integrator: integrator,
        /*channelid: channelId,
        msgtext: msgText,
        url: url*/
        slackmsg: slackMsg
      });
    }
  
    private getApiConversationList(integrator: TIngegrator/*, orgCode: string*/): Observable<any> {
      return this.http.post(environment.firebase.functionsUrl+'/getConversationsList', { integrator: integrator });
    }

    private getApiMembersList(integrator: TIngegrator/*, orgCode: string*/): Observable<any> {
      return this.http.post(environment.firebase.functionsUrl+'/getMembersList', { integrator: integrator });
    }

    public checkSlack(integrator: TIngegrator): Promise<any> {
      return new Promise((resolve, reject) => {
      this.apiCheckSlack(integrator)
        .subscribe(
          (data: boolean) => {
            //console.log('single owner top 3 data:',data);
            resolve(data);
            //this._checkIntegration.next(data);
          },
          error => {
            reject(error); // Reject if there is an error in fetching data
            //this._checkIntegration.next(false);
          }
        );
      });
    }

      public getSlackConversationList(): Promise<IRespSlackConversationList> {
        this._dataSlackConversationList.next({data:[], status: 'cleared'});
        return new Promise((resolve, reject) => {
          this.getApiConversationList('slack')
          .pipe(
          )
          .subscribe(
            async (convs: any) => {
              resolve(convs);
              this._dataSlackConversationList.next({data: convs, status: 'success'});
              //console.log('Conv list:', convs);
            },
            error => {
              reject(error);
              this._dataSlackConversationList.next({data: [], status: 'failed'});
            }
          );
        });
      }

      public getSlackMembersList(): Promise<IRespSlackMembersList> {
        this._dataSlackMembersList.next({data:[], status: 'cleared'});
        return new Promise((resolve, reject) => {
          this.getApiMembersList('slack')
          .pipe(
          )
          .subscribe(
            async (convs: any) => {
              resolve(convs);
              this._dataSlackMembersList.next({data: convs, status: 'success'});
              console.log('User list:', convs);
            },
            error => {
              reject(error);
              this._dataSlackMembersList.next({data: [], status: 'failed'});
            }
          );
        });
      }

      public shareInsightViaSlack(channelId: string, element: ICardInsightsItem | ICardInsights1on1Item | ICardInsights1on1PatternItem, elementType: TInsightCategory) {
        return new Promise((resolve, reject) => {
          const link = this.share.generateLink('element-'+element.insightId.toString());
          const sharedMessage = this.shareInsightOnSlack(channelId, element, elementType, link);
          this.apiSendMessageToSlack('slack', sharedMessage/*, channelId,styledText, link*/)
            .subscribe(
              (data: any) => {
                //console.log('single owner top 3 data:',data);
                resolve(data);
                //this._checkIntegration.next(data);
                if (data?.data?.ok == true) {
                  this.alertServ.showAlert({
                    messageTitle: 'Slack message sent',
                    message: 'Insight has been successfully shared via Slack',
                    errorStatus: 'success'
                  }, 'success')
                }
              },
              error => {
                reject(JSON.stringify(error)); // Reject if there is an error in fetching data
                this.alertServ.showAlert({
                  messageTitle: 'Slack error',
                  message: error?.error,
                  errorStatus: `${error?.statusText} - ${error?.status}`
                }, 'danger')
                //this._checkIntegration.next(false);
              }
            );
          });
      }


    private shareInsightOnSlack(
        channelId:string, 
        element: ICardInsightsItem | ICardInsights1on1Item | ICardInsights1on1PatternItem, 
        elementType: TInsightCategory,
        link: string,
      ) {
      let returnedText: any = null;
      switch(elementType) { //insight type
        case 'deal' : { 
          returnedText = this.prepActionableInsight(
            channelId, 
            element as ICardInsightsItem, 
            link
          );
          break; 
        }
        case 'oneonone' : { 
          returnedText = this.prepM1on1DetailedInsight(
            channelId, 
            element as ICardInsights1on1Item, 
            link
          );
          break;
        }
        case 'oneonone-pattern' : { 
          returnedText = this.prepPatterns(
            channelId, 
            element as ICardInsights1on1PatternItem, 
            link
          );
          break;
        }
        default : {}
      }
      return returnedText;
      

    }

    private prepActionableInsight(channelId:string, element: ICardInsightsItem, link: string) {
      return this.fillInsightMessageText(
        channelId,
        '⚡️ Actionable Insight',
        element.insightText,
        element.insightWhy,
        element.dealName,
        element.pipeline,
        link
      );
    }

    private prepM1on1DetailedInsight(channelId:string, element: ICardInsightsItem, link: string) {
      return this.fillInsightMessageText(
        channelId,
        '🚦 1on1 Insight',
        element.insightText,
        element.insightWhy,
        element.dealName,
        element.pipeline,
        link
      );
    }

    private prepPatterns(channelId:string, element: ICardInsights1on1PatternItem, link: string) {
      return this.fillPatternMessageText(
        channelId,
        '🧩 Discovered Pattern in',
        element.insightText,
        element.insightWhy,
        element.pipeline,
        link,
        this.uSet.shorterCategory(element.category),
        this.uSet.convertMillisToTimestamp(element?.lastSourceTextCreatedMillis) as string,
        element.dealIds.length,
        element.dealOwnerEmails.length,
        element.dealAmountSum?.toString()
      );
    }
    

    private fillInsightMessageText(
      channelId:string, 
      headerText: string,
      mainText: string,
      contextText: string,
      dealName: string,
      pipeline: string,
      insightUrl: string,
    ) {
      /* use Block Kit to design output json: https://app.slack.com/block-kit-builder */
      const currUser: User | any = getAuth().currentUser;

      const userBlock = this.setSharedByBlock();
      return {
        channel: channelId,
        //as_user: true,
        blocks: [
        {
          ...userBlock
        },
        {
          type: "header",
          text: {
            type: "plain_text",
            text: headerText//":among-us-party: Insight"
          }
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `*${mainText}*`,//`*<${link}|${element.insightText}>*`
          }
        },
        {
          type: "context",
          elements: [
            {
              type: "mrkdwn",
              text: `*Why?* ${contextText}`
            }
          ]
        },
        {
          type: "divider"
        },
        {
          type: "section",
          fields: [
            {
              type: "mrkdwn",
              text: `*Deal Name:*\n${dealName}`
            },
            {
              type: "mrkdwn",
              text: `*Pipeline:*\n${pipeline}`
            }
          ]
        },
        {
          type: "actions",
          elements: [
            {
              type: "button",
              text: {
                type: "plain_text",
                text: "Go to Insight"
              },
              url: insightUrl
            }
          ]
        }
        ]
    };
    }

    private fillPatternMessageText(
      channelId:string, 
      headerText: string,
      mainText: string,
      contextText: string,
      pipeline: string,
      insightUrl: string,
      category: string,
      lastObserved: string,
      numOfDeals: number,
      numOfOwners: number,
      assocAmt: string
    ) {
      /* use Block Kit to design output json: https://app.slack.com/block-kit-builder */
      const currUser: User | any = getAuth().currentUser;
      const showAssocAmount = (amt:string) => {
        return amt ? ` • *Amount:* ${amt+',-'}` : '';
      }

      const userBlock = this.setSharedByBlock();
      return {
        channel: channelId,
        //as_user: true,
        blocks: [
        {
          ...userBlock
        },
        {
          type: "header",
          text: {
            type: "plain_text",
            text: `${headerText} ✨${category}✨`//":among-us-party: Insight"
          }
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `*${mainText}*`,//`*<${link}|${element.insightText}>*`
          }
        },
        {
          type: "context",
          elements: [
            {
              type: "mrkdwn",
              text: `*Why?* ${contextText}`
            }
          ]
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `🔗 *Deals:* ${numOfDeals} • *Owners:* ${numOfOwners}${showAssocAmount(assocAmt)}`//*Amount:* ${assocAmt+',-'}`
          }
        },
        {
          type: "divider"
        },
        {
          type: "section",
          fields: [
            {
              type: "mrkdwn",
              text: `*Pipeline:*\n${pipeline}`
            },
            {
              type: "mrkdwn",
              text: `*Last Observed:*\n${lastObserved}`
            }
          ]
        },
        {
          type: "actions",
          elements: [
            {
              type: "button",
              text: {
                type: "plain_text",
                text: "Go to Pattern"
              },
              url: insightUrl
            }
          ]
        }
        ]
    };
    }


    private setSharedByBlock() {
      /* use Block Kit to design output json: https://app.slack.com/block-kit-builder */
      const currUser: User | any = getAuth().currentUser;

      return {
        type: "context",
        elements: [
          {
            type: "image",
            image_url: currUser.photoURL,
            alt_text: "Sender Avatar"
          },
          {
            type: "mrkdwn",
            text: `Shared by *${currUser?.displayName}*`
          }
        ]
      };
    }
}
