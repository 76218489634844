import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from './icons/icon-subset';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.dev';
import { AuthService } from './shared/services/auth.service';
import { OrgsettingService } from './shared/services/orgsetting.service';
import { UserInvitationsService } from './shared/services/user-invitations.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { User } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { IOwnerLisItem } from './shared/interfaces/ownerdata';
import { OrgdataService } from './shared/services/orgdata.service';
import { AnalyticsService } from './shared/services/analytics.service';
import { distinctUntilChanged, Subject, take, takeUntil } from 'rxjs';
import { ShareContentService } from './shared/services/share-content.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'AIMARS';
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private titleService: Title,
    private iconSetService: IconSetService,
    private authService: AuthService,
    private invitServ: UserInvitationsService,
    //public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    private ga: AnalyticsService,
    private orgSet: OrgsettingService,
    private share: ShareContentService
  ) {
    titleService.setTitle(this.title);
    // iconSet singleton
    iconSetService.icons = { ...iconSubset };
  }

  ngAfterViewInit(): void {
    //Listen to shared links
    this.share.listenToHashInUrl();
  }

  ngOnInit(): void {
    //console.log('Main app component loaded..............');
    console.warn('Environment PRODUCTION:', environment.production, environment.deployment.gitCommit); // Logs false for development environment
    // Turn on Amplitude tracking: 
    /*
    this.ga.getUserId().then((uid) => {
      console.log('GA | uid', uid);
      this.ga.getUserOrg().then((org) => {
        console.log('GA | org', org);
        this.ga.initializeAmplitude(uid,org);
    })*/
      
    this.orgSet.getLastOrgChange()
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((org) => {
        this.ga.getUserId().then((uid) => {
          this.ga.initializeAmplitude(uid,org);
        })
      })
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
  
    if (environment.switchOffConsole == true) {
      this.switchOffConsole();
    }

    this.retriveDataOnInit();

    this.ga.LogClickEvent({
        _eventCategory: 'App Access',
        _eventAction: 'App Load',
        _eventLabelName: 'AIMARS'
      });
    

  }

  switchOffConsole(){
    if(window){
      window.console.log = function(){};
      window.console.warn = function(){};
      window.console.error = function(){};
    }
  }

  retriveDataOnInit(){
    //this.authService.getUserDetails().then(());
    //this.authService.getCurrentLoggedUser();
    //this.authService.redirectToLoginIfNotLogged();

    //////const currOrgCode = JSON.parse(localStorage.getItem('orgCodeCurr')!)
    //////this.orgSet.$orgChange.next(currOrgCode); //to do: take last current org from user data in firebase

    //Invitations
    this.invitServ.checkNewInvites();
    this.authService._navPermit.next(null);
    
  }
}
