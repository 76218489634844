import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ButtonModule, ModalModule } from '@coreui/angular';
import { IConfirmMsg, NotificationsService, TConfirmColor, TModalType } from 'src/app/shared/services/notifications.service';

@Component({
  selector: 'app-popup-confirm',
  templateUrl: './popup-confirm.component.html',
  styleUrls: ['./popup-confirm.component.scss'],
  standalone: true,
  imports: [ModalModule, ButtonModule, CommonModule]
})
export class PopupConfirmComponent implements OnInit {
  constructor(private alertService: NotificationsService){}
  public confirmMessage: IConfirmMsg | null = null;
  public modType: TConfirmColor = 'info';
  public showModal: boolean = false;
  public confirmFunc!: any;
  public modalType: TModalType = 'confirm';

  ngOnInit(): void {
    this.alertService.confirmModal.subscribe(mod => {
      this.modalType = mod.message?.modalType || 'confirm';
      this.showModal = true;
      this.confirmMessage = mod.message;
      this.modType = mod.type;
      this.confirmFunc = mod.message?.confirmFunc;
    });
  }

  onCancel():void {
    this.showModal = false;
  }

  onConfirm():void {
    this.showModal = false;
    return this.confirmFunc();

  }




}
