import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AvatarModule, ButtonModule, FormModule, ListGroupModule, ModalModule, SpinnerModule } from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';
import { BehaviorSubject, debounceTime, Subject, take, takeUntil, withLatestFrom } from 'rxjs';
import { TapiStatus } from 'src/app/shared/interfaces/dataresp';
import { IInviteEntry, TOrgRole } from 'src/app/shared/interfaces/invites';
import { ApiSlackService } from 'src/app/shared/services/api-integrators/api-slack.service';
import { OrgsettingService } from 'src/app/shared/services/orgsetting.service';
import { IModalChooeShareChannel, ShareContentService } from 'src/app/shared/services/share-content.service';
import { UserInvitationsService } from 'src/app/shared/services/user-invitations.service';

export interface ISelectedMember {
  elemType: 'member' | 'channel';
  id: string;
  name: string;
  profile: {
    image_24: string;
  }
}
export interface ISelectedChannel {
  elemType: 'member' | 'channel';
  id: string;
  name: string;
  is_private: boolean;
  is_member: boolean;
  //channel_icon: string;
  profile: {
    image_24: string;
  }
}
@Component({
  selector: 'modal-share-choosechannel',
  templateUrl: './modal-share-choosechannel.component.html',
  styleUrls: ['./modal-share-choosechannel.component.scss'],
  standalone: true,
  imports: [ModalModule,FormModule,ReactiveFormsModule, ButtonModule, CommonModule, AvatarModule, IconModule, SpinnerModule]
})
export class ModalShareChoosechannelComponent implements  OnInit, OnChanges{
  public sharedElement!: IModalChooeShareChannel;

  public modalTitle!: string;
  public searchForm: FormGroup;
  public filterBySearchInitial: (ISelectedChannel| ISelectedMember)[] = [];
  public filterBySearch!: (ISelectedChannel| ISelectedMember)[];
  public selectedOption!: ISelectedChannel| ISelectedMember | undefined;
  private destroy$: Subject<void> = new Subject<void>();
  public loadStatus: TapiStatus = 'cleared';

  //public channelList!: any[];
  //public membersList!: any[];


  //public visible = false;

  constructor(
    private fb: FormBuilder,
    private shareServ: ShareContentService,
    private slack: ApiSlackService,
    private orgSet: OrgsettingService
  ) {

    this.searchForm = this.fb.group({
      searchChannels: ['', [Validators.required]],
      //role: ['', Validators.required]
    });
  }
  ngOnInit(): void {
    this.filterBySearchInitial = [];
    this.filterBySearch = [];

    this.modalTitle = 'Select Slack channel or member';
    //this.filterBySearchInitial = ['U05GWBUG60P','C086MCH82N6', 'U05H95UUX26', 'testC','test1', 'test2', 'test3'];

    this.shareServ._showChooseChannelModal.subscribe(showModal => {
      this.sharedElement = {
        show: showModal?.show,
        element: showModal?.element,
        elementType: showModal?.elementType
      }

      if (showModal.show) {
        this.loadChannelsAndMembers();
      }
    })

    this.searchForm.get('searchChannels')?.valueChanges
        .pipe(
          debounceTime(300),  // Delay search to prevent too frequent calls
          takeUntil(this.destroy$)
        )
        .subscribe((searchTerm: string) => {
          (searchTerm == this.selectedOption?.name) ? null : this.handleSearch(searchTerm);
        });
    
    this.slack._dataSlackConversationList
      .pipe(
        withLatestFrom(this.orgSet.$orgChange),
        takeUntil(this.destroy$),
        //take(1) // at start there are two emissions of modified email
      ).subscribe(([list,org]) => {
        console.log('SHARE LIST:',list);
          const showList:ISelectedChannel[] = list?.data?.data
          ?.filter((elem:any) => !elem?.deleted && !elem?.is_bot && elem.is_member)
          ?.map((elem: ISelectedChannel):ISelectedChannel => ({ 
            elemType: 'channel',
            id: elem?.id, 
            name: elem?.name,
            is_private: elem?.is_private,
            is_member: elem?.is_member,
            //channel_icon: (elem?.is_private) ? 'cil-lock-locked' : 'cil-lock-unlocked'
            profile: {
              image_24: (elem?.is_private) ? 'cil-lock-locked' : 'cil-lock-unlocked'
            }
          }));
          (showList) ? this.filterBySearchInitial = this.filterBySearchInitial.concat(showList) : null;
          
          this.filterBySearch = this.filterBySearchInitial;
          this.loadStatus = list?.status;
          console.log('SHARE LIST:','showList', this.loadStatus);
         
    });
    
    this.slack._dataSlackMembersList
      .pipe(
        withLatestFrom(this.orgSet.$orgChange),
        takeUntil(this.destroy$),
        //take(1) // at start there are two emissions of modified email
      ).subscribe(([members, org]) => {
        
        const showMembers:ISelectedMember[] = members?.data?.data
        ?.filter((elem:any) => !elem?.deleted && !elem?.is_bot)
        ?.map((elem: ISelectedMember):ISelectedMember => ({ 
          elemType: 'member',
          id: elem?.id, 
          name: elem?.name,
          profile: {
            image_24: elem?.profile?.image_24
          }
        }));
        
        (showMembers) ? this.filterBySearchInitial = this.filterBySearchInitial.concat(showMembers): null; //.concat(showMembers);
        
        this.filterBySearch = this.filterBySearchInitial;
        this.loadStatus = members?.status;
        console.log('SHARE MEMBRS:', showMembers, this.loadStatus);
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    //const editEmail = (this.editMember) ? this.editMember.email : '';
    //const editRole = (this.editMember) ? this.editMember.role : '';

    //this.modalTitle = 'Select channel or member';
    this.searchForm = this.fb.group({
      searchChannels: ['', [Validators.required]],
      //role: [editRole, Validators.required]
    });
  }

  onSubmit() {
    if (this.searchForm.valid && this.selectedOption) {
      const formData: IModalChooeShareChannel = this.searchForm.value as IModalChooeShareChannel;
      console.log('Form Submitted', this.searchForm.value);
      
      this.toggleModal(this.sharedElement);
      this.slack.shareInsightViaSlack(this.selectedOption.id,this.sharedElement.element, this.sharedElement.elementType);

    } else {
      console.log('Form is invalid');
    }
    
  }

  toggleModal(element: IModalChooeShareChannel) {
    
    const toggleElement:IModalChooeShareChannel = {
      element: element.element,
      elementType: element.elementType,
      show: !element.show
    }
    console.log('Toggle modal:', toggleElement);
    this.shareServ._showChooseChannelModal.next(toggleElement);
  }

  handleSearch(searchTerm: string): void {
    console.log('SHARE Search term:', searchTerm);
    
    //if (searchTerm?.length > 2) {
      const data = this.filterBySearchInitial;
      this.filterBySearch = data.filter(item => item?.name?.toLocaleLowerCase().includes(searchTerm?.toLocaleLowerCase()));
      //this.oDat._dataOrg1on1Subject.next({data: filterBySearch, status: 'success'});
    //}
  
    if (searchTerm?.length === 0) {
      //this.oDat._dataOrg1on1Subject.next({data: this.m1on1dataFilteredByCategoriesAndPatterns, status: 'success'});
      //remove filter
    }
    
  }

  handleKeyPressInSearch(event:any) {
    this.handleSearch(event);
  }

  selectOption(option: ISelectedChannel | ISelectedMember) {
    if(!this.selectedOption || this.selectedOption.id != option.id) {
      this.selectedOption = option;
      this.searchForm.setValue({searchChannels: option.name});
      console.log('SHARE Selected:', option, this.selectedOption);
    } else {
      this.selectedOption = undefined;
      //this.searchForm.reset();
      this.filterBySearch = this.filterBySearchInitial;
      console.log('SHARE UnSelected:', option, this.selectedOption);
    }
    
  }

  preventSubmit(event: KeyboardEvent | any): void {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevents form submission
    }
  }

  private loadChannelsAndMembers() {
    this.filterBySearchInitial = [];
    this.filterBySearch = [];
    this.slack.getSlackConversationList();
    this.slack.getSlackMembersList();
  }
}
