import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface IAlertMsg {
  messageTitle: string | null,
  message: string | null,
  errorStatus: string | null
};

export interface IConfirmMsg {
  modalType?: TModalType,
  messageTitle: string | null,
  message: string | null,
  confirmFunc: any
};

export type TConfirmColor = 'danger' | 'warning' | "info" | "success";
export type TModalType = 'confirm' | 'info';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private _alertSubject = new Subject<{message: IAlertMsg | null, type: TConfirmColor}>();
  public alertState = this._alertSubject.asObservable();

  private _confirmModalSubject = new Subject<{message: IConfirmMsg | null, type: TConfirmColor}>();
  public confirmModal = this._confirmModalSubject.asObservable();

  showAlert(message: IAlertMsg | null, type: TConfirmColor = 'danger') {
    this._alertSubject.next({ message, type });
  }

  showConfirmModal(message: IConfirmMsg | null, type: TConfirmColor = 'info') {
    this._confirmModalSubject.next({ message, type });
  }
}
