import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AlertModule, ButtonModule } from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';
import { slideInOutAnimation } from 'src/app/animations/animations';
import { IAlertMsg, NotificationsService, TConfirmColor} from 'src/app/shared/services/notifications.service';

@Component({
  selector: 'app-popup-alert-belt',
  templateUrl: './popup-alert-belt.component.html',
  styleUrls: ['./popup-alert-belt.component.scss'],
  standalone: true,
  imports: [AlertModule, CommonModule, IconModule, ButtonModule],
  styles: [],
  animations: [slideInOutAnimation]
})
export class PopupAlertBeltComponent implements OnInit{

  constructor(private alertService: NotificationsService){}
  public alertMessage: IAlertMsg | null = null;
  visible = true;

  public alertType: TConfirmColor = 'danger';
  public animationState: 'initial' | 'final' = 'initial'; 

  ngOnInit(): void {
    this.alertService.alertState.subscribe(alert => {
      this.animationState = 'final';
      this.alertMessage = alert.message;
      this.alertType = alert.type;
      setTimeout(() => {
        this.onClose();
      }, 4000);
    });
  }

  onClose() {
    this.animationState = 'initial';
    setTimeout(() => {
      this.alertMessage = null;
    }, 300);
  }

  onVisibleChange(eventValue: boolean) {
    console.log('visible: ', eventValue);
    this.visible = true;
    
  }



  

}
